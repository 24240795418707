import React, { useEffect, useState } from 'react';
import { useBibliographyJsonData } from 'hooks';

import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Button, Breadcrumb, Hero, Dropdown, Table } from 'components/ui';
import BibliographyHeroImage from 'assets/images/hero-research-bibliography.jpg';

const isBrowser = typeof window !== 'undefined';
const initialItemSelection = 0;

const BibliographySearch = ({ location }) => {
  // set path for breadcrumb
  let thisLocationPathname = '';
  if (isBrowser) {
    thisLocationPathname = window.location.pathname;
  }

  // set state params
  const [bibliographyItems, setBibliographyItems] = useState([]);
  const [bibliographyAllItems, setBibliographyAllItems] = useState([]);
  const [bibliographyItemTypes, setBibliographyItemTypes] = useState([]);
  const [bibliographyItemTags, setBibliographyItemTags] = useState([]);
  const [selectedType, setSelectedType] = useState(initialItemSelection);
  const [selectedTag, setSelectedTag] = useState(initialItemSelection);
  const [downloadableData, setDownloadableData] = useState([]);
  const [isResetColumns, setResetColumns] = useState(false);

  // const columns to show on table
  const tableColumns = [
    { id: 1, name: 'Title', width: '60%' },
    { id: 2, name: 'Year', width: '10%' },
    { id: 3, name: 'Authors', width: '30%' },
  ];

  // Read data from JSON file using query and set in table
  const { allBibliography: bibliographyPayload } = useBibliographyJsonData();
  // let queryData = [];

  useEffect(() => {
    const topNode = bibliographyPayload?.nodes[0];
    const allData = topNode?.bibliography || [];
    const bibliographyItemTypes = topNode?.bibliographyType;
    const bibliographyItemTags = topNode?.bibliographyTag;

    setBibliographyItems(allData || []);
    setBibliographyAllItems(allData || []);
    setBibliographyItemTypes(bibliographyItemTypes || []);
    setBibliographyItemTags(bibliographyItemTags || []);
  }, [bibliographyPayload]);

  // filter data based on both filter value
  const filterTableData = (tagValue, typeValue) => {
    setSelectedTag(tagValue);
    setSelectedType(typeValue);
    if (tagValue === 0 && typeValue === 0) {
      setBibliographyItems([...bibliographyAllItems]);
    } else if (tagValue === 0 && typeValue !== 0) {
      setBibliographyItems([
        ...bibliographyAllItems.filter(
          (item) => item.BibliographyTypeID === typeValue
        ),
      ]);
    } else if (tagValue !== 0 && typeValue === 0) {
      const tagFilterList = bibliographyPayload.nodes[0].bibliographyTagFilters;
      const newArr = tagFilterList.filter(
        (filterTag) => filterTag.BibliographyTagID === tagValue
      );
      setBibliographyItems([
        ...bibliographyAllItems.filter((rawDataObj) => {
          return newArr.find((filteredObj) => {
            return filteredObj.BibliograhpyID === rawDataObj.ID;
          });
        }),
      ]);
    } else {
      const typeFiltered = bibliographyAllItems.filter(
        (item) => item.BibliographyTypeID === typeValue
      );
      const tagFilterList = bibliographyPayload.nodes[0].bibliographyTagFilters;
      const newArr = tagFilterList.filter(
        (filterTag) => filterTag.BibliographyTagID === tagValue
      );
      setBibliographyItems([
        ...typeFiltered.filter((rawDataObj) => {
          return newArr.find((filteredObj) => {
            return filteredObj.BibliograhpyID === rawDataObj.ID;
          });
        }),
      ]);
    }
  };

  // reset values of both filter dropdown
  const resetValue = () => {
    filterTableData(0, 0);
    setResetColumns(true);
  };

  // find keywords data for CSV file
  const findKeywords = (bibliographyId) => {
    const filteredTagList =
      bibliographyPayload.nodes[0].bibliographyTagFilters.filter(
        (filteredTag) => filteredTag.BibliograhpyID === bibliographyId
      );
    return bibliographyPayload.nodes[0].bibliographyTag
      .filter((tag) => {
        return filteredTagList.find(
          (filteredTag) => filteredTag.BibliographyTagID === tag.ID
        );
      })
      .map((itemTag) => itemTag.Name)
      .join('; ');
  };

  // Download citiatios
  const downloadCitations = () => {
    // Headers for each column
    let headers = [
      'Title,Year,Authors,BibliographyType,Keywords,Publication Title,ISBN,ISSN,DOI,Url,Pages,Issue,Volume,Journal Abberviation,Publisher,Place,Link Attachments,RecordsTotal,RecordsFiltered',
    ];

    // Convert users data to a csv
    let usersCsv = downloadableData.reduce((arr, data) => {
      const {
        ID,
        Title,
        PublicationYear,
        Author,
        BibliographyTypeID,
        PublicationTitle,
        ISBN,
        ISSN,
        DOI,
        Url,
        Pages,
        Issue,
        Volume,
        JournalAbbreviation,
        Publisher,
        Place,
        LinkAttachments,
      } = data;
      const newArr = [
        Title,
        PublicationYear,
        Author,
        bibliographyItemTypes.find(
          (type) => Number(type.ID) === BibliographyTypeID
        ).Name,
        findKeywords(ID),
        PublicationTitle,
        ISBN,
        ISSN,
        DOI,
        Url,
        Pages,
        Issue,
        Volume,
        JournalAbbreviation,
        Publisher,
        Place,
        LinkAttachments,
        bibliographyAllItems.length,
        downloadableData.length,
      ].map((string) => (string === null ? '' : string.match && string.match(/,|"/) ? `"${string.replaceAll('"','""')}"` : `"${string}"`));
      arr.push(newArr.join(','));
      return arr;
    }, []);

    const csvData = [...headers, ...usersCsv].join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;encoding:utf-8' });
    const a = document.createElement('a');
    a.download = 'bibliography.csv';
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  let bibliographyKeywords=[...bibliographyItemTags];
  const sortBibliographyKeywords = list=>{
    return list.sort((a, b) => {
      if (a.Name < b.Name) {
          return -1;
      }
  });
  }

  return (
    <Layout location={location}>
      <SeoComponent title="Interactive Bibliography Search" description="Search the results of NCSACW’s literature review of books, journal articles, and reports related to child welfare, substance use disorders, legal, safety, risk, co-occurring disorders, drug testing, screening, assessment, engagement, family-centered, fetal alcohol spectrum disorder, FASD, evidence-based practice, medication-assisted treatment, MAT, opioids, plan of safe care, and trauma-informed care." keywords="literature review of books, journal articles, and reports" />
      <div className="margin-bottom-3">
        <Hero img={BibliographyHeroImage} color="#f5f5f5">
          <Breadcrumb
            pathname={thisLocationPathname}
            customCurrentPage={'Interactive Bibliography Search'}
          />
          <h1
            className="usa-hero__heading"
            name="main-content"
            id="main-content"
          >
            Interactive Bibliography Search
          </h1>
          <p>
            The bibliography is a resource for individual citations identified in the field of cross-system issues involving child welfare, substance use disorders, and family treatment and dependency courts.
          </p>
        </Hero>
      </div>

      <div className="">
        <div className="grid-container">
        <div className="desktop:grid-col-8 tablet:grid-col-12">
          <p>
            The citations are searchable by topical keyword, author, title, date, or publication. Citations can also be exported through comma-separated values (csv) file.
          </p>
        </div>
          <div className="grid-row">
            <div className="desktop:grid-col-4 desktop:padding-right-2 tablet:grid-col-6 tablet:padding-right-4">
              <Dropdown
                name="keywords"
                label="Keywords:"
                selected={selectedTag}
                handleChange={(e) =>
                  filterTableData(Number(e.target.value), Number(selectedType))
                }
                size={'maxw-full'}
              >
                <option value={0}>All Keywords</option>
                {sortBibliographyKeywords(bibliographyKeywords)?.map((itemTag, index) => (
                  <option id={`keyword-item-${index}`} key={`keyword-item-${index}`} value={itemTag.ID}>
                    {itemTag.Name}
                  </option>
                ))}
              </Dropdown>
            </div>
            <div className="desktop:grid-col-3 desktop:padding-right-2 tablet:grid-col-6">
              <Dropdown
                name="itemtypes"
                label="Item Type:"
                selected={selectedType}
                handleChange={(e) =>
                  filterTableData(Number(selectedTag), Number(e.target.value))
                }
                size={'maxw-full'}
              >
                <option value={0}>Select a Type</option>
                {bibliographyItemTypes.map((itemType, index) => (
                  <option id={`item-type-${index}`} key={`item-type-${index}`} value={itemType.ID}>
                    {itemType.Name}
                  </option>
                ))}
              </Dropdown>
            </div>
            <div className="desktop:grid-col-3 padding-right-2 tablet:grid-col-4 margin-y-6 download-btn">
              <Button
                size="padding-2 width-full"
                color="primary-button"
                onClick={(e) => downloadCitations()}
              >
                Download Citations
              </Button>
            </div>
            <div className="desktop:grid-col-2 desktop:padding-right-2 tablet:grid-col-2 margin-y-6 reset-btn">
              <Button
                size="padding-2 width-full"
                color="primary-button"
                onClick={(e) => resetValue()}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>

        <Table
          columns={tableColumns}
          rows={bibliographyItems}
          totalCount={bibliographyAllItems.length}
          isResetColumns={isResetColumns}
          setResetColumns={setResetColumns}
          setUpdatedData={(updatedData) => {
            setDownloadableData(updatedData);
          }}
        />
        {/* <TableU /> */}
      </div>
    </Layout>
  );
};
export default BibliographySearch;
